import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Layout, Menu, Breadcrumb, Dropdown, Button } from 'antd'
import { LogoutOutlined, HomeOutlined, TagsOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import useAuth from 'AppStore/auth/auth.hook'
import CreateBuilderButton from 'views/builder/CreateBuilderButton'
import useBuilder from 'AppStore/builder/builder.hook'
import { getAllBuilderParts } from 'api/api-builders'

const { SubMenu } = Menu
const { Header, Content, Sider, Footer } = Layout

// project imports

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {

	const location = useLocation()
	const { pathname } = location
	const {  loginByToken } = useAuth()

	const [breadcrumbs, setBreadcrumbs] = useState<string[]>([
		// PATHS.DASHBOARD,
		// PATHS.BUILDERS,
	])

	const { auth: { profile }, onSignOut} = useAuth()
	const { builder: { builders }, fetchBuildersSaga, fetchBuilderPartsSaga} = useBuilder()

	const { builder_id } = useParams()
	let openMenuItem = String(Math.max(builders.findIndex((ele:any, key:number)=>ele.id == builder_id), 0))
	if(!builder_id){
		openMenuItem = String(builders.length)
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})	
		loginByToken()
	}, [pathname])

	useEffect(()=>{
		fetchBuildersSaga()
		fetchBuilderPartsSaga()
	}, [])

	const onClickLogoutBtn = () => {
		onSignOut()
		window.location.href = '/'
	}
	

	return (
		<Layout>
			<Header style = {{ display:'flex', alignItems:'center', justifyContent:'space-between', position:'fixed', width:'100%', zIndex:10, paddingLeft:'16px'}}>
				<div className="logo">
					<a href="#">
						<img src="/site-logo-white.svg" width = "176" />
					</a>
				</div>
				<Dropdown menu={{
					items: [
						{
							key: '1',
							label: (
								<div onClick={onClickLogoutBtn}>
									<LogoutOutlined /> Logout
								</div>
							),
						},
					]
				}} placement="bottomLeft">
					<Button>{profile.display_name}</Button>
				</Dropdown>
			</Header>
			<Layout >
				<Sider style={{ paddingTop:'60px', height:'100vh', position:'fixed', overflowY:'auto', left:'0'}}>

					<div style={{ fontSize:'18px', color:'#fff', fontWeight:'bold', paddingLeft	:'8px'}}>Builders</div>
					<div style={{ minHeight:'300px'}}>
						<Menu 
							theme="dark" 
							mode="inline"
							defaultSelectedKeys={[openMenuItem]} 
							defaultOpenKeys={[openMenuItem]} 
							openKeys={[openMenuItem]} 
							selectedKeys = {[openMenuItem]}>
							{
								builders.map((ele:any, key:number)=>
									<Menu.Item
										key={key}
										icon={<HomeOutlined />}
										onClick={() => {
										// linkTo([PATHS.DASHBOARD, PATHS.HOUSETYPES])
										}}
									>
										<Link to={`/dashboard/builders/${ele.id}`}>{ele.title}</Link>
									</Menu.Item>
								)
							}

						</Menu>
					
						<CreateBuilderButton />

					</div>
					
					<div style={{ fontSize:'18px', color:'#fff', fontWeight:'bold', paddingLeft	:'8px'}}>Master Parts</div>
					<Menu 
						theme="dark" 
						mode="inline" 
						defaultSelectedKeys={[openMenuItem]} 
						defaultOpenKeys={[openMenuItem]} 
						openKeys={[openMenuItem]} 
						selectedKeys = {[openMenuItem]}>
						<Menu.Item icon={<TagsOutlined />} key = {builders.length}>
							<Link to = {'/dashboard/masterparts'}>Master Parts</Link>
						</Menu.Item>
					</Menu>

				</Sider>
				<Layout style={{ padding: '60px 0px 0px', marginLeft: 200, backgroundColor:'#2f77ff1a' }}>
					<Breadcrumb style={{ margin: '16px 0', textTransform: 'capitalize' }}>
						{breadcrumbs.map((e, i) => (
							<Breadcrumb.Item key={i}>{e.replace('/', '')}</Breadcrumb.Item>
						))}
					</Breadcrumb>
					<Content style={{ padding:'24px', margin:0, minHeight:'500px'}}>
						<Outlet />
					</Content>
					<Footer style={{ textAlign: 'center' }}>SA Stairs Spec Site ©2023</Footer>
				</Layout>
			</Layout>
		</Layout>
	)}

export default MinimalLayout
