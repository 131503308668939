import React, { lazy } from 'react'

// project imports
import Loadable from 'components/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

// login option 3 routing
const LoginForm = Loadable(lazy(() => import('views/authentication/LoginForm')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
	path: '/',
	element: <MinimalLayout />,
	children: [
		{
			path: '/',
			element: <LoginForm />
		}
	]
}

export default AuthenticationRoutes
