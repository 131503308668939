import { createSlice } from '@reduxjs/toolkit'
import localStore from 'utils/localstore'

const initialState = {
	builders: [],
	builderParts:[],
}

const reducers = {
	setBuilders: (state, action) => {
		const { payload } = action
		state.builders = payload
	},
	addNewBuilder: (state, action) => {
		const { payload } = action
		state.builders = [...state.builders, payload]
	},
	setBuilderParts: (state, action) => {
		const { payload } = action
		state.builderParts =payload
	}
}

const slice = createSlice({ name: 'builders', initialState, reducers })

export const { 
	setBuilders, 
	addNewBuilder, 
	setBuilderParts, 
} = slice.actions

export default slice.reducer
