import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	isOpen: [], // for active default menu
	opened: true
}

const reducers = {
	setMenu: (state, action) => {
		const { payload } = action
		state.opened = payload
	},
	menuOpen: (state, action) => {
		const { payload } = action
		state.isOpen = [payload]
	},
	menuToggle: (state, action) => {
		// To Do
		const { payload } = action
		state.fontFamily = payload
	},
	setFontFamily: (state, action) => {
		// To Do
		const { payload } = action
		state.fontFamily = payload
	},
	setBorderRadius: (state, action) => {
		// To Do
		const { payload } = action
		state.borderRadius = payload
	}
}

const slice = createSlice({ name: 'customization', initialState, reducers })

export const { setMenu, menuToggle, setFontFamily, menuOpen, setBorderRadius } = slice.actions

export default slice.reducer
