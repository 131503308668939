
import React, { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'components/Loadable'

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')))
const BuilderView = Loadable(lazy(() => import('views/builder')))
const MasterPartsView = Loadable(lazy(() => import('views/masterparts')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '/dashboard',
	element: <MainLayout />,
	children: [
		{
			path: 'default',
			element: <DashboardDefault />,
		},
		{
			path: 'builders/:builder_id',
			element: <BuilderView />,
		},
		{
			path: 'masterparts',
			element: <MasterPartsView />,
		}
	]
}

export default MainRoutes
