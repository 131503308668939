import React from 'react'
import AppRoutes from 'routes'
import NavigationScroll from 'layout/NavigationScroll'

function App() {
	return (
		<AppRoutes />
	)
}

export default App
