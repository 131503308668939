import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from './auth.reducer'
import { sagaActions } from './auth.saga.action'

const useAuth = () => {
	const dispatch = useDispatch()
	const auth = useSelector((state) => {
		return state.authReducer
	})
	const setProfile = useCallback(
		(profile) => {
			dispatch(actions.setProfile(profile))
		},
		[dispatch]
	)

	const onSignin = useCallback(
		(payload) => {
			dispatch({ type: sagaActions.LOGIN_SAGA, payload })
		},
		[dispatch],
	)

	const onSignOut = useCallback(
		() => {
			dispatch(actions.signOut())
		},
		[dispatch],
	)

	const loginByToken = useCallback(() => {
		dispatch({ type: sagaActions.LOGIN_BY_TOKEN })
	}, [dispatch])

	return { auth, setProfile, onSignin, loginByToken, onSignOut }
}

export default useAuth
