import axios from 'axios'
import localStore from 'utils/localstore.js'

export const getRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.get(route, {
			params: {
				...payloads,
			},
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStore.getToken()}`,
			}
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const postRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.post(route, payloads)
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const securePostRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.post(route, payloads, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStore.getToken()}`,
			}
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const putRequest = (route, payloads) => {
	return new Promise((resolve, reject)=>
		axios.put(route, payloads, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStore.getToken()}`,
			}
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}

export const deleteRequest = (route) => {
	return new Promise((resolve, reject)=>
		axios.delete(route, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStore.getToken()}`,
			}
		})
			.then((response)=>resolve(response))
			.catch((error)=>reject(error)))
}