import { getRequest, postRequest, putRequest, securePostRequest } from './api-requests'
import config from './config'

export const sigin = (payload) => {
	return postRequest(`${config.host}/api/auth/login`, payload)
}

export const loginByToken = () => {
	return securePostRequest(`${config.host}/api/auth/loginByToken`)
}
