import { sagaActions } from './auth.saga.action'
import * as actions from './auth.reducer'
import { takeEvery, put, call } from 'redux-saga/effects'
import { loginByToken, sigin } from 'api/api-auth'


function* signInRequest(data: any) {
	const { email, password } = data.payload
	let response:{data:any}
	try {
		response = yield call(sigin, { email, password })
		yield put(actions.setProfile(response.data))
		window.location.href = '/dashboard/default'
	} catch (e) {
		console.log(e)
	}
}

function* loginByTokenRequest() {
	try {
		const response:{data:any} = yield call(loginByToken)
		yield put(actions.setProfile(response.data))
	} catch (e) {
		// window.location.href = '/'
		console.log(e)
	}
}


export default function* watchAuthSaga() {
	// yield fork(loginWatcherSaga);
	yield takeEvery(sagaActions.LOGIN_SAGA, signInRequest)
	yield takeEvery(sagaActions.LOGIN_BY_TOKEN, loginByTokenRequest)
}
