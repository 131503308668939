import { Spin } from 'antd'
import React from 'react'
// material-ui
// import LinearProgress from '@mui/material/LinearProgress'
// import { styled } from '@mui/material/styles'

// styles
// const LoaderWrapper = styled('div')({
// 	position: 'fixed',
// 	top: 0,
// 	left: 0,
// 	zIndex: 1301,
// 	width: '100%'
// })

// ==============================|| LOADER ||============================== //
const Loader = () => (
	<div style={{
		position:'fixed',
		top:0,
		left:0,
		width:'100%',
		textAlign:'center',
		zIndex:1301,
	}}>
		<Spin />
	</div>
)

export default Loader
