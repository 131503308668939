import { createSlice } from '@reduxjs/toolkit'

const initialState: any  = {
	photos: [],
	paginationOption: {total:0, curPage:1, pageSize:48},
}

const reducers:any = {
	setPhotos: (state: any, action: { payload: any }) => {
		const { payload } = action
		console.log('payload ==>', payload)
		state.photos = payload
	},
	setPaginationOption: (state: any, action: { payload: any[] }) => {
		const { payload } = action
		state.paginationOption = payload
	},
}

const slice = createSlice({ name:'gallery', initialState, reducers })

export const { setPhotos, setPaginationOption }:any = slice.actions 
export default slice.reducer
