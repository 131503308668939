import React, { useState } from 'react'
import { Button, Card, Checkbox, Form, Input, Modal, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { create } from 'api/api-builders'
import useBuilder from 'AppStore/builder/builder.hook'



const CreateBuilderModal: React.FC = () => {

	const [open, setOpen] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [modalText, setModalText] = useState('Content of the modal')
	const { builder:{builders}, addNewBuilder } = useBuilder()

	console.log('builders ==>', builders)
	
	const [ form ] = Form.useForm()

	const showModal = () => {
		setOpen(true)
	}

	const handleOk = () => {
		form.submit()
	}

	const handleCancel = () => {
		console.log('Clicked cancel button')
		setOpen(false)
	}

	const onFinish = (values: any) => {
		setConfirmLoading(true)
		create(values).then((res:any)=>{
			addNewBuilder(res.data)
			setConfirmLoading(false)
			setOpen(false)
			notification.open({
				message: 'System',
				type:'info',
				description:'New Builder is created!',
			})
		})
	}

	const onFinishFailed = () => {
		console.log('---onFinishFailed---')
	}

	

	return (
		<>
			<div style={{ padding:'8px'}}>
				<Button type="primary" style={{ width:'100%'}} icon = {<PlusOutlined />} onClick={showModal}>Add New Builder</Button>
			</div>
			<Modal
				title="New Builder"
				open={open}
				onOk={handleOk}
				confirmLoading={confirmLoading}
				onCancel={handleCancel}
			>
				<Form initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
					<Form.Item name="title" rules={[{ required: true, message: 'Please input buider title' }]}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</>
	)
}

export default CreateBuilderModal