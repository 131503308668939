import storage from 'store'
import sessionStorage from 'store/storages/sessionStorage'
import cookieStorage from 'store/storages/cookieStorage'

const localStore = Object.freeze({
	set: (type, value) => storage.set(type, value),
	get: (type) => storage.get(type),
	remove: (type) => storage.remove(type),
	clear: () => {
		storage.clearAll()
	},
	setToken: (token) => cookieStorage.write('user_token', token),
	getToken: () => cookieStorage.read('user_token'),
	removeToken: () => cookieStorage.remove('user_token'),
	setSession:(key, value) => sessionStorage.write(key, value),
	getSession:(key) => sessionStorage.read(key),
	clearSession:() => sessionStorage.clearAll(),
	setCookie:(key, value) => cookieStorage.write(key, value),
	getCookie:(key) => cookieStorage.read(key),
	clearCookie:(key) => cookieStorage.remove(key)
})

export default localStore