import { useMemo } from 'react'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, combineReducers, compose, configureStore } from '@reduxjs/toolkit'

import authReducer from './auth/auth.reducer'
import watchAuthSaga from './auth/auth.saga'

import builderReducer from './builder/builder.reducer'
import watchBuilderSaga from './builder/builder.saga'

import galleryReducer from './gallery/gallery.reducer'

import customizationReducer from './customization/customization.reducer'

function initStore(initialState:any) {
	const sagaMiddleware = createSagaMiddleware()

	const rootReducer = combineReducers({
		authReducer,
		galleryReducer,
		builderReducer,
		customizationReducer
	})

	const store = configureStore({
		reducer: rootReducer,
		preloadedState: initialState,
		middleware: [sagaMiddleware]
	})

	sagaMiddleware.run(watchAuthSaga)
	sagaMiddleware.run(watchBuilderSaga)

	return store
}

const store:any = initStore({})

export { store }
