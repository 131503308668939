
import { deleteRequest, getRequest, postRequest, putRequest, securePostRequest } from './api-requests'
import config from './config'

export const getAll = () => getRequest(`${config.host}/api/builder-spec/builder`)
export const getBuilderByUri = (uri) => getRequest(`${config.host}/api/builder-spec/builder/uris/${uri}`)
export const getBuilderById = (id) => getRequest(`${config.host}/api/builder-spec/builder/ids/${id}`)
export const create = (payload) => securePostRequest(`${config.host}/api/builder-spec/builder`, payload)
export const createNewUser = (payload) => securePostRequest(`${config.host}/api/builder-spec/builder/users`, payload)
export const deleteUser = (id) => deleteRequest(`${config.host}/api/builder-spec/builder/users/${id}`)
export const deleteBuilder = (id) => deleteRequest(`${config.host}/api/builder-spec/builder/${id}`)
export const updateBuilder = (id, payloads) => putRequest(`${config.host}/api/builder-spec/builder/${id}`, payloads)
export const updateBuilderUser = (id, payloads) => putRequest(`${config.host}/api/builder-spec/builder/users/${id}`, payloads)
export const createNewBuilderPart = (payloads) => securePostRequest(`${config.host}/api/builder-spec/builder/parts`, payloads)
export const getAllBuilderParts = () => getRequest(`${config.host}/api/builder-spec/builder/parts`)
export const deleteBuilderParts = (id) => deleteRequest(`${config.host}/api/builder-spec/builder/parts/${id}`)
export const updateBuilderParts = (id, payloads) => putRequest(`${config.host}/api/builder-spec/builder/parts/${id}`, payloads)
export const updateBuilderPartOption = (id, payloads) => putRequest(`${config.host}/api/builder-spec/builder/${id}/options`, payloads)

export const getAllProjectsOfBuilder = (builder_id) => getRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects`)
export const getBuilderProjectDetailById = (builder_id, project_id) => getRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects/${project_id}`)
export const createNewBuilderProject = (builder_id, payload) => postRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects/`, payload)
export const updateBuilderProject = (builder_id, project_id, payload) => putRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects/${project_id}`, payload)
export const updateProjectsOrderids = (builder_id, payload) => putRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects/orders/update`, payload)
export const deleteBuilderProject = (builder_id, project_id) => deleteRequest(`${config.host}/api/builder-spec/builder/${builder_id}/projects/${project_id}`)