import { sagaActions } from './builder.saga.action'
import * as actions from './builder.reducer'
import { takeEvery, put, call } from 'redux-saga/effects'
import { getAll, create, getAllBuilderParts } from 'api/api-builders'


function* fetchBuilderSaga(data: any) {
	let response:{data:any}
	try {
		response = yield call(getAll)
		yield put(actions.setBuilders(response.data))
	} catch (e) {
		console.log(e)
	}
}

function* fetchBuilderPartsSaga(data: any) {
	let response:{data:any}
	try {
		response = yield call(getAllBuilderParts)
		yield put(actions.setBuilderParts(response.data))
	} catch (e) {
		console.log(e)
	}
}

function* createBuilderSaga(data: any) {
	let response:{data:any}
	try {
		response = yield call(create, data.payload)
		yield put(actions.addNewBuilder(response.data))
	} catch (e) {
		console.log(e)
	}
}


export default function* watchAuthSaga() {
	// yield fork(loginWatcherSaga);
	yield takeEvery(sagaActions.FETCH_BUILDER_SAGA, fetchBuilderSaga)
	yield takeEvery(sagaActions.CREATE_BUILDER_SAGA, createBuilderSaga)
	yield takeEvery(sagaActions.CREATE_BUILDER_SAGA, createBuilderSaga)
	yield takeEvery(sagaActions.FETCH_BUILDER_PARTS_SAGA, fetchBuilderPartsSaga)
}
