import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from './builder.reducer'
import { sagaActions } from './builder.saga.action'

const useBuilder = () => {
	const dispatch = useDispatch()
	const builder = useSelector((state) => {
		return state.builderReducer
	})

	const fetchBuildersSaga = useCallback(
		() => {
			dispatch({ type: sagaActions.FETCH_BUILDER_SAGA })
		},
		[dispatch],
	)
	
	const fetchBuilderPartsSaga = useCallback(
		() => {
			dispatch({ type: sagaActions.FETCH_BUILDER_PARTS_SAGA })
		},
		[dispatch],
	)

	const addNewBuilder = useCallback(
		(payload) => {
			dispatch(actions.addNewBuilder(payload))
		},
		[dispatch],
	)
	
	return { builder, fetchBuildersSaga, addNewBuilder, fetchBuilderPartsSaga }
}

export default useBuilder
