import { createSlice } from '@reduxjs/toolkit'
import localStore from 'utils/localstore'

const initialState = {
	profile: { },
}

const reducers = {
	setProfile: (state, action) => {
		const { payload } = action
		localStore.setToken(payload.user_token)
		
		state.profile = payload
	},

	signOut: (state, action) => {
		const { payload } = action
		localStore.removeToken()
		state.profile = {}
	}
}

const slice = createSlice({ name: 'auth', initialState, reducers })

export const { setProfile, signOut } = slice.actions
export default slice.reducer
